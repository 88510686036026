<div class="sign-verify-container">
  <div class="sign-verify-form">
    <i (click)="close()" class="fa-solid fa-xmark close-icon pointer mt-1" ></i>
    <div class="header-form">
      <h5 class="sub-title"><span i18n="@@verifyPhoneMessage">Confirm your mobile number
        The temporary password was sent to</span><br/>
        {{phoneNumber}}
      </h5>
    </div>

      <form action="">

        <div class="p-field">
          <div class="code-container" [dir]="'ltr'">
            <ng-otp-input  [formCtrl]="verifyFormControl" (onInputChange)="onInputChange($event)" [config]="{length:6,allowNumbersOnly:true}"></ng-otp-input>
          </div>
          <div *ngIf="errorObj.isRequired && errorObj.isDirty" i18n="@@required" class="error-msg">*Required</div>
          <!-- <app-form-errors
                  [color]="'danger'"
                  [form]="verifyFormControl"
                  [control]="verifyFormControl"
                  >
          </app-form-errors> -->
        </div>

        <button type="submit" (click)="send()" [ngClass]="{'valid-form-btn': verifyFormControl.value?.length == 6}" class="secondary-btn md-btn" [disabled]="loadingSubmit || loadingResendCode">
            <span i18n="@@signIn">Sign In</span>
            <i *ngIf="loadingSubmit" class="fa-spin fas fa-spinner"></i>
        </button>

        <div class="resend-pass">
          <span i18n="@@passwordNotArrived">The password has not arrived </span>
          <a (click)="resendCode()" i18n="@@resend" class="resend-space"> resend</a>
          <i *ngIf="loadingResendCode" class="fa-spin fas fa-spinner"></i>
        </div>

      </form>

      <!-- <div *ngIf="previousForm == 'signIn'" class="footer-form">
          <a (click)="openSignUpPopup()">Create Account</a>
      </div> -->

  </div>

  <div class="left-side">
    <img src="./assets/images/auth-cover-min2.png" alt="cover">
  </div>

</div>

<div id="recaptcha-container"></div>
