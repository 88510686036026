import { APP_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './modules/auth/auth.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SignInComponent } from './modules/auth/components/sign-in/sign-in.component';
import { SignUpComponent } from './modules/auth/components/sign-up/sign-up.component';
import { VerifyPhoneNumberComponent } from './modules/auth/components/verify-phone-number/verify-phone-number.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { UserInterceptorInterceptor } from './core/interceptors/user-interceptor.interceptor';
import { LanguageInterceptor } from './core/interceptors/language.interceptor';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ErrorCodesInterceptor } from './core/interceptors/error-codes.interceptor';
import { NgOtpInputModule } from 'ng-otp-input';
import { CustomNotificationComponent } from './shared/components/custom-notification/custom-notification.component';
import { FormErrorsComponent } from './shared/components/form-errors/form-errors.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FooterComponent } from "./shared/components/footer/footer.component";
import localeAr from '@angular/common/locales/ar';
import { registerLocaleData } from '@angular/common';

export function getLocalStorage() {
  return typeof window !== 'undefined' ? window.localStorage : null;
}
registerLocaleData(localeAr);
@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    SignInComponent,
    SignUpComponent,
    VerifyPhoneNumberComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'IMakeUp' }),
    AppRoutingModule,
    NgOtpInputModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    CustomNotificationComponent,
    FormErrorsComponent,
    BrowserAnimationsModule,
    NgxSkeletonLoaderModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    HttpClientModule,
    AccordionModule,
    FooterComponent,
],
  providers: [
    // provideClientHydration(),
    { provide: 'CACHE_KEY', useValue: '' },
    // provideClientHydration(),
    { provide: APP_ID,  useValue: 'IMakeUp' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCodesInterceptor,
      multi: true,
    },
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
