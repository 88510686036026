import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productDetailsPath',
  standalone:true
})
export class ProductDetailsPathPipe implements PipeTransform {

  transform(category: any): string {
    let slug:string = category?.length > 0 ? category[0]?.slug : '';
    // if(!slug){return 'category/sub_category'}
    return slug
    const slugsArr = slug.split("/");
    return slugsArr[0] + '/' + slugsArr[1];
  }

}
