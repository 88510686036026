import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from 'src/app/modules/account/services/account.service';

@Injectable({
  providedIn: 'root'
})
export class CustomNotificationService {

  public notificationSubject = new BehaviorSubject<{type: string , message : string}>({type: '' , message : ''}) ;
  newNotification$ = this.notificationSubject.asObservable() ;

  constructor(private account: AccountService) { }
  
  showNotification(notification : {type: string , message : string}){
    this.notificationSubject.next(notification)
  }
}
