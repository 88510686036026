<ng-template #authPopup>
      <div class="modal-body">
        <!-- here render all auth forms -->
        <!-- sign in form component -->
        <app-sign-in
            (closeOutput)="closePopup($event)"
            *ngIf="currentForm == 'signIn'"
        ></app-sign-in>
        <!-- sign up form component -->
        <app-sign-up
            (closeOutput)="closePopup($event)"
            *ngIf="currentForm == 'signUp'"
        ></app-sign-up>
        <!-- verify phone number form component -->
        <app-verify-phone-number
            (closeOutput)="closePopup($event)"
            *ngIf="currentForm == 'verifyForm'"
        ></app-verify-phone-number>

      </div>
</ng-template>