<div class="custom-modal pt-3" #templateModal>
  <div class="modal-body">
    <div class="container model-content">
      <div class="popup-content row">
        <div class="left-side col-md-5">
          <div class="button-container">
            <button routerLink="/cart.html" (click)="goToCart()">
              <img src="./assets/images/white-cart2.svg" alt="cart-logo" />
              <ng-container i18n="@@goCart">Go to Cart</ng-container>
            </button>
            <button (click)="closeModal()" i18n="@@continueShopping">
              Continue Shopping
            </button>
          </div>
          <div class="product">
            <img
              [src]="selectedProduct?.base_image?.small_image_url"
              alt="product-img"
            />
            <p>{{ selectedProduct?.name }}</p>
          </div>
        </div>
        <div class="right-side col-md-7">
          <div class="products-container">
            <!-- Start loader -->
            <ng-container *ngIf="isLoading">
              <a
                *ngFor="let i of [1, 2, 3, 4]"
                class="d-flex flex-column justify-content-between"
              >
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{
                    'border-radius': '5px',
                    height: '120px',
                    border: '1px solid white',
                    width: '240px'
                  }"
                ></ngx-skeleton-loader>
                <p class="mb-0">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{
                      'border-radius': '5px',
                      height: '15px',
                      border: '1px solid white',
                      width: '240px'
                    }"
                  ></ngx-skeleton-loader>
                </p>
              </a>
            </ng-container>
            <!-- End loader -->
            <div class="product" *ngFor="let product of products">
              <!-- [isConfigurableProduct] = true -->
              <app-product-card
                [product]="product"
                [isAddedToCart]="product?.is_item_in_cart || false"
                ></app-product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
