import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackgroundColorService {
  public backgroundColorSubject = new BehaviorSubject<any>({home :'#FDFDFD' , navbarAllCategories :'#F9F9F9' , navbarItems:'#F5F5F5' })
  backgroundColor$ = this.backgroundColorSubject.asObservable();
  isFirefox !: boolean ;
  constructor() { }
  setBackgroundColor(color :any){
    this.backgroundColorSubject.next(color)
  }
}
