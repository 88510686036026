import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  standalone:true,
  selector: 'app-small-footer',
  templateUrl: './small-footer.component.html',
  styleUrls: ['./small-footer.component.scss'],
  imports:[
    CommonModule,
    RouterModule,
    NgxSkeletonLoaderModule
  ]
})
export class SmallFooterComponent {
  @Input() isContactLinksLoading: boolean = false;
  @Input() contacts: any = {};
  constructor() {}

  ngOnInit(): void {}

  getCurrentYear(): number {
    return new Date().getFullYear();
  }

  maskNumber(number:string , positions : number[]) : string | null {
    if(number?.length === 15){
      return number
    }else if (number?.length === 13 ){
      const chars = number?.split('');
      positions.forEach((position : number)=>{
        if( position <= chars.length ){
          chars.splice(position ,0 , ' ');
        }
      })
      return chars.join('');
    }else if(number?.length === 10 ){
      return "+966 ".concat(number);
    }else if(number?.length === 9 ){
      let formattedNumber = `${number.slice(0,4)} ${number.slice(4)}`
      return "+966 ".concat(formattedNumber);
    }
    return null
  }
}
