import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

type Forms = 'signIn' | 'signUp' | 'verifyForm';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuth: boolean = false;
  // isAdmin: any = this.isUserAdmin();
  baseUrl = environment.baseUrl;

  authPopup: Subject<{ isOpen: boolean; form: Forms }> = new BehaviorSubject<{
    isOpen: boolean;
    form: Forms;
  }>({isOpen : false , form:"signUp"});

  previousFormStream: Subject<Forms> = new Subject<Forms>();

  // Service to send user state if logged or not to navbar

  private userState: BehaviorSubject<string> = new BehaviorSubject<string>(
    'guest'
  );
  isAuthenticatedSubject = new BehaviorSubject(false);
  private isUserObjBeingRetrieved = false;
  authCompActive : boolean = false;
  recaptchaService !:Injector;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject('LOCALSTORAGE') private localStorage: any
  ) {}

  getUserState() {
    return this.userState.asObservable();
  }

  setUserToken(token: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem('iMakeUpToken', token);
    }
    this.isAuthenticatedSubject.next(true);
  }

  setUserObj(userObj: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem('iMakeUpObj', JSON.stringify(userObj));
    }
    this.isAuthenticatedSubject.next(true);
  }

  signOut() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.localStorage?.getItem('iMakeUpToken')) {
        this.localStorage.removeItem('iMakeUpToken');
      }
      this.localStorage.removeItem('iMakeUpObj');
    }
    this.isAuthenticatedSubject.next(false);
  }

  getUserToken() {
    if (isPlatformBrowser(this.platformId)) {
      return this.localStorage.getItem('iMakeUpToken');
    }
  }
  getUserObj() {
    if (this.isUserObjBeingRetrieved) {
      return {};
    }
    if (isPlatformBrowser(this.platformId)) {
      try{
        this.isUserObjBeingRetrieved = true;
        const userObjStr = this.localStorage.getItem('iMakeUpObj');
        if (userObjStr) {
          this.isAuthenticatedSubject.next(true);
          const parsedObj = JSON.parse(userObjStr);
          this.isUserObjBeingRetrieved = false;
          return parsedObj;
        }
      }
      catch (error) {
        // console.error('Error retrieving from localStorage', error);
      }
      finally {
        this.isUserObjBeingRetrieved = false;
      }
    }
    return {};
  }

  checkUserAuth() {
    if (this.getUserToken()) {
      this.isAuth = true;
    }
  }

  isUserAuth() {
    return this.isAuth;
  }

  checkIsAdmin() {
    return this.getUserObj()?.is_admin;
  }

  routeToHomeAndOpenLogin(){
    this.authCompActive =true ;
    this.authPopup.next({ isOpen: true, form: 'signIn' });

  }

  // setTempAuth(status : boolean){
  //   this.localStorage.setItem('iMakeUpToken', JSON.stringify(status));
  //   this.isAuth = true;
  //   this.userState.next('user');
  //   this.checkUserAuth();
  // }
}
