import { Injectable } from '@angular/core';
import { Product } from 'src/app/shared/models/interfaces/product';

@Injectable({
  providedIn: 'root'
})
export class BigProductCardMapperService {
  constructor() { }
  
  transformProduct(product: any) {
    const imageUrl =
      product?.base_image?.medium_image_url ??
      product?.images[0]?.medium_image_url;
      const originalPrice:any = (parseFloat(product.price).toFixed(2));
      let discountedPrice:any;
      let discountPercentage:any;
      if(product?.special_price){
       discountedPrice = (parseFloat(product?.special_price).toFixed(2));
       discountPercentage = (((originalPrice-discountedPrice)/originalPrice)*100).toFixed(2)
      }
      const isConfigurable = product?.type === "configurable" ? true : false
      const productColors = product?.configurable_attributes?.map((attribute:any )=> attribute.color_swatch_value);
      const selectedColors = productColors?.slice(0, 6);
      const numberOfRemainingColors = (productColors?.length - 6) > 0 ? productColors?.length - 6 : ""
      const volumes = product?.configurable_attributes?.map((attribute: any) => attribute?.size_name);
      const uniqueVolumes = volumes?.every((volume: any) => volume !== undefined) ? [...new Set(volumes)] : [];

      return {
      isProductLabel: product?.isNew,
      imageUrl,
      cardBackground: '#FAFAFA',
      description: product?.name,
      rating:Math.round(product?.reviews?.average_rating  ),
      reviews: product?.reviews?.total || 0,
      discountedPrice,
      originalPrice,
      discountPercentage,
      isDiscountLabel: true,
      productUrl: product?.url_key,
      productColors:selectedColors,
      productVolums:uniqueVolumes,
      numberOfRemainingColors,
      isConfigurable,
    };
  }
  transformProductsArray(productsArray : any , favourites? : boolean ){
    if(!favourites){
      return productsArray.map((product:Product)=>{
        const reviews = {...product?.reviews , average_rating : +product?.reviews?.average_rating! }
        const price = (product?.special_price)? product?.special_price : product?.price ;
        const currentPrice = {
          priceFirstPart : price &&
          (price?.toString() || "")!.split(".")[0] ?
          (price?.toString() || "")!.split(".")[0] :
          '0',
          priceSecondPart : (price?.toString() || "")!.split(".")[1] ?
          (price?.toString() || "")!.split(".")[1]?.slice(0, 2)!.padEnd(2,'0') : '00' ,
          originalPrice : product?.price ? Number(product?.price)?.toFixed(2)?.replace(".",",") : "" ,
          price
        }
        return { ...product , reviews , currentPrice }
  
        }) 
    }else{
      return productsArray.map((p:any)=>{
        const reviews = {...p?.product?.reviews , average_rating : +p?.product?.reviews?.average_rating! }
        const price = p?.product?.special_price ? p?.product?.special_price : p?.product?.price ;
        const currentPrice = {
          priceFirstPart : price &&
          (price?.toString() || "")!.split(".")[0] ?
          (price?.toString() || "")!.split(".")[0] :
          '0',
          priceSecondPart : (price?.toString() || "")!.split(".")[1] ?
          (price?.toString() || "")!.split(".")[1]?.slice(0, 2)!.padEnd(2,'0') : '00' ,
          originalPrice : p?.product?.price ? Number(p?.product?.price)?.toFixed(2)?.replace(".",",") : "" ,
          price
        }
        return { ...p , product : {...p?.product , reviews , currentPrice }  }
  
        }) 
    }

  }
}
