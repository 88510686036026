import { Directive, HostListener, Inject, Input, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[navigateUrl]',
  standalone: true
})
export class NavigateUrlDirective {

  @Input() navigateUrl: string = ''; // The route to navigate to
  constructor(private router: Router,
    @Inject(LOCALE_ID) public lang: string
  ) { }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.preventDefault(); // Prevent the default anchor behavior
    if (this.navigateUrl.includes("makeup.com") || this.navigateUrl.includes("localhost")) {
      this.router.navigate([this.getParamUrl(this.navigateUrl)],{queryParams:this.getQueryParamUrl(this.navigateUrl)});
    }else{
      window.open(this.navigateUrl, '_blank');
    }
  }

  getParamUrl(url:string):string{
    if(typeof url != 'string' || url == '#' || !url || url== ' '){
      return 'invalid-url'
    }
    let _url:string = '/' + url?.split(`/${this.lang}/`)[1] || '';
    return _url.split("?")[0]
  }

  getQueryParamUrl(url:String):any{
    if(typeof url != 'string' || url == '#' || !url || url== ' '){
      return {}
    }
    let _url:string = '/' + url?.split(`/${this.lang}/`)[1] || '';

    let _qParamsArr = _url?.split("?")[1]?.split("&"); // array ['brand=12','x=5']
    if(!_qParamsArr?.length){return {}}
    let _query:any = {};
    _qParamsArr.forEach((e)=>{
      const _p = e?.split('=');
      _query[_p[0]] = _p[1];
    })
    return _query;

  }

}
