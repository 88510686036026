import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';


const routes: Routes = [
  {path:'checkout',loadChildren:()=>import("./modules/checkout/checkout.module").then((m)=> m.CheckoutModule)},
  {path:'',loadChildren:()=>import("./modules/main-layout/main-layout.module").then((m)=> m.MainLayoutModule)},
];

@NgModule({ 
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
