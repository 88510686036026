import { HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Inject, LOCALE_ID, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { HttpService } from 'src/app/core/services/http.service';
import { ValidationService } from 'src/app/core/services/validation.service';
import 'firebase/auth';
import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AccountService } from 'src/app/modules/account/services/account.service';
import { SaveActionService } from 'src/app/core/services/save-action.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
@Component({
  selector: 'app-verify-phone-number',
  templateUrl: './verify-phone-number.component.html',
  styleUrls: ['./verify-phone-number.component.scss']
})
export class VerifyPhoneNumberComponent implements OnInit,AfterViewInit{
  @Output() closeOutput = new EventEmitter();
  verifyFormControl:FormControl = new FormControl([Validators.required]);
  loadingSubmit:boolean = false;
  loadingResendCode:boolean = false;
  codes:any;
  previousForm:any = '';
  verificationId:any;
  phoneNumber:any;
  reCaptchaVerifier : any = null;
  errorObj:{isRequired:boolean,isDirty:boolean} = {isRequired:false,isDirty:false};
  otpMessage:any = $localize`:@@otpMessage:OTP sent successfully. Please check your phone for the code.`;
  verificationCodeErrorMessage:any = $localize`:@@verificationCodeErrorMessage:The SMS verification code used to create the phone auth credential is invalid.`;
  firebaseGeneralError:any = $localize`:@@firebaseGeneralError:An error occurred. Please try again later.`;
  firebaseInvalidPhoneNumber: any = $localize`:@@firebaseInvalidPhoneNumber:Invalid-phone-number.`;
  recaptcha !: ReCaptchaV3Service;
  constructor(
    private _auth:AuthService,
    private _api: HttpService,
    private notifications: CustomNotificationService,
    private accountService : AccountService,
    private saveActions: SaveActionService,
    @Inject(LOCALE_ID) public lang: string,
    @Inject(DOCUMENT) private document: Document

    ){
  }

  ngOnInit(): void {
    this.recaptcha = this._auth.recaptchaService.get(ReCaptchaV3Service) ;
    // listen previous form stream
    this._auth.previousFormStream.subscribe(form=>{
      // console.log(form)
      if(form){
        this.previousForm = form;
      }
    })

    firebase.initializeApp(environment.firebaseConfig);
    this.verificationId = JSON.parse(localStorage.getItem('verificationId') || '{}');
    const phone = localStorage.getItem('phoneNumber');
    if (phone) {
      this.phoneNumber = this.lang === "ar" ? `${phone.slice(10)} ${phone.slice(7,10)} ${phone.slice(4, 7)} ${phone.slice(1, 4)}+` : phone
    }
  }
  close(){
    this.closeOutput.emit()
  }
  ngAfterViewInit(): void {
    // this.codes = this.document.querySelectorAll('.code-container .code');
    // this.codes[0].focus();
  }

  // onKeyDown(event:any){
  //   let currentIndex=parseInt(event.target.getAttribute('data-index'));
  //   if(event.key >= 0 && event.key <=9){
  //     event.target.value='';
  //     if(currentIndex!=5){
  //       setTimeout(()=>this.codes[currentIndex+1].focus(),10)
  //     }else{
  //       setTimeout(()=>this.codes[currentIndex].blur(),10)
  //     }
  //   }else if(event.key === 'Backspace'){
  //     if(currentIndex!=0 && !event.target.value){
  //       setTimeout(()=>this.codes[currentIndex-1].focus(),10)
  //     }
  //   }
  //   // else{
  //   //   setTimeout(()=>event.target.value='',0.5);
  //   // }

  // }


  // onPaste(event: ClipboardEvent) {
  //   let pastedText = event?.clipboardData?.getData('text');
  //   const digits = pastedText?.match(/\d{6}/);
  //   if (digits) {
  //     const [d1, d2, d3, d4,d5,d6] = digits[0].split('');
  //     this.verifyForm.patchValue({
  //       codeOne: d1,
  //       codeTwo: d2,
  //       codeThree: d3,
  //       codeFour: d4,
  //       codeFive: d5,
  //       codeSix: d6,
  //     });
  //   }
  //   event.preventDefault();
  // }

  // getCompletedPinCode() {
  //   let complete_pinCode: any = ''
  //   Object.keys(this.verifyForm.controls).forEach(key => {
  //     if (key != 'g-recaptcha-response') {
  //       complete_pinCode += String(this.verifyForm.get(key)?.value)
  //     }
  //   });
  //   return complete_pinCode
  // }

  send() {
    const otp:string = this.verifyFormControl.value;
    if (otp.length < 6) {
      this.errorObj.isDirty = true;
      this.errorObj.isRequired = true;
      return
    }
    let credential = firebase.auth.PhoneAuthProvider.credential(
      this.verificationId,
      otp
    );
    // console.log('credential', credential);
    this.loadingSubmit = true;
    firebase
      .auth()
      .signInWithCredential(credential)
      .then((response) => {
        // Get the User object from UserCredential
        // console.log('response125', response);
        // localStorage.setItem('user_data', JSON.stringify(response));
        const user = response.user;
        // Get the ID token (access token)
        return user?.getIdToken();
      })
      .then((idToken) => {
        // console.log('ID Token (Access Token):', idToken);
        if (idToken) {
          this.checkToken(idToken);
        }
      })
      .catch((error) => {
        this.loadingSubmit = false;
        this.notifications.showNotification({
          type: 'lg-error',
          message: this.verificationCodeErrorMessage,
        });
      });
  }

  onInputChange(e:any){
    if(e?.length == 6){
      this.errorObj.isRequired = false;
      return
    }
    if(this.errorObj.isDirty){this.errorObj.isRequired = true}
  }

  openSignUpPopup(){
    this._auth.authPopup.next({isOpen:true,form:'signUp'});
  }

  checkToken(accessToken: string) {
    // console.log('accessToken', accessToken);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });
    this._api.postReq('/customer/check-token', {}, { headers }).subscribe(
      (res) => {
        this._auth.setUserToken(res?.token);
        this._auth.setUserObj(res?.data);
        this.loadingSubmit = false;
        this.saveActions.doAction();
        this._auth.authPopup.next({ isOpen: false, form: 'verifyForm' });
        this.notifications.showNotification({
          type: 'success',
          message: res?.message,
        });
        this.accountService.getFavList();
        this.accountService.getAllCartItemList();
      },
      (err) => {
        // console.log('check-tokenERR', err);
        let errorMsg : any = "";
        if(err?.status == 422){
          errorMsg = err?.error?.message;
           this._auth.authPopup.next({ isOpen: true, form: 'signUp' });

        }else{
          errorMsg = err?.message;
        }
        this.notifications.showNotification({
          type: 'lg-error',
          message: errorMsg,
        });
        this.loadingSubmit = false;
      }
    );
  }

  resendCode(){
    this.verifyFormControl.reset();
    this.loadingResendCode = true;
    this.getOTP();
  }

  initializeRecaptcha() {
    if (!this.reCaptchaVerifier) {
      this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        }
      );
    }
  }

  getOTP() {
    this.initializeRecaptcha();
    let errorMessage: string;
    firebase
      .auth()
      .signInWithPhoneNumber(this.phoneNumber, this.reCaptchaVerifier)
      .then((confirmationResult) => {
        //close loader
        this.loadingResendCode = false;
        // next step
        this.notifications.showNotification({
        type: 'success',
        message: this.otpMessage,
        });
        localStorage.setItem(
          'verificationId',
          JSON.stringify(confirmationResult.verificationId)
        );
      })
      .catch((error) => {
        this.loadingResendCode = false;
        if (this.lang === 'ar') {
          if (error.message.includes('auth/invalid-phone-number')) {
            errorMessage = this.firebaseInvalidPhoneNumber;
          } else {
            errorMessage = this.firebaseGeneralError;
          }
        } else {
          errorMessage = error.message;
        }
        this.notifications.showNotification({
          type: 'lg-error',
          message: errorMessage,
        });
      });
  }
}
