import { Component, ElementRef, Input , OnInit, Renderer2 , Inject, LOCALE_ID, inject, PLATFORM_ID   } from '@angular/core';
import { CommonModule, getLocaleDirection, isPlatformServer } from '@angular/common';
@Component({
  standalone:true,
  selector: 'app-notification-type',
  templateUrl: './notification-type.component.html',
  styleUrls: ['./notification-type.component.scss'],
  imports:[
    CommonModule
  ]
})
export class NotificationTypeComponent implements OnInit {

  pageDir : string = 'ltr'
  hideNotification : any ;
  @Input() type : any ;
  @Input() message : any;
  @Input() show : any ;
  platform_id:Object = inject(PLATFORM_ID);
  constructor(@Inject(LOCALE_ID) public lang: string,
              private renderer: Renderer2,
              private el: ElementRef){}

  ngOnInit(){
    this.pageDir = isPlatformServer(this.platform_id) ? 'rtl' : getLocaleDirection(this.lang);
  }
  hide(){
    this.hideNotification = true ;
  }
  removeComponent(){
    this.renderer.removeChild(this.el.nativeElement.parentElement, this.el.nativeElement);
  }
}
