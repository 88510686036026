<!-- <button (click)="setRtl()">Change</button> -->

<div class="notification">
    <div class="notification-container"
    [ngClass]="{
        'success-type' : type === 'success' ,
        'cart-type' :  type === 'add-cart' ||  type === 'remove-cart',
        'wishlist-type' : type === 'wishlist' ,
        'warning-type' : type === 'warning' ,
        'error-type' : type === 'sm-error' || type === 'lg-error',
        'api-style' : ( type === 'success' || type === 'lg-error' )  ||  type === 'sm-error',
        'hide-notification' : hideNotification
    }"
    (click)="hide()"
    >
    <div class="small-notification" *ngIf="type === 'add-cart' || type === 'remove-cart'">
        <img  src="./assets/icons/notifications/success-cart-notification.svg" />
        <p *ngIf="type === 'add-cart' " i18n="@@addedCartSuccessfully">Added to cart successfully</p>
        <p *ngIf="type === 'remove-cart'" i18n="@@removedCartSuccessfully">Removed from cart successfully</p>
    </div>
    <div class="small-notification" *ngIf=" type === 'wishlist'">
        <img  src="./assets/icons/notifications/wishlist-notification.svg" />
        <p *ngIf="type === 'wishlist'" >{{message ? message : ''}}</p>
    </div>
    <div class="small-notification" *ngIf="type === 'sm-error'">
        <img src="./assets/icons/notifications/not-err.svg" />
        <p> {{message ? message : ''}} </p>
    </div>

    <div class="large-notification" *ngIf="type === 'lg-error'" >
        <img src="./assets/icons/notifications/not-err.svg" />
        <p class="text-break"> {{message ? message : '' }} </p>
    </div>

    <div class="large-notification" *ngIf="type === 'warning'" >
      <img src="./assets/images/warning.png" />
      <p class="text-break">
          {{message ? message : '' }}
        </p>
    </div>

    <div class="large-notification" *ngIf="type === 'success'" >
        <img src="./assets/icons/notifications/success-cart-notification.svg" />
        <p class="text-break"> {{message ? message : '' }} </p>
    </div>
</div>
</div>

<!-- <p> Added to cart successfully Added to cart successfully Added to cart successfully Added to cart successfully Added to cart successfully </p> -->

<!-- <p> Added to cart successfully Added to cart successfully Added to cart successfully Added to cart successfully Added to cart successfully </p> -->
