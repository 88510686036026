// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://staging-dashboard.i-makeup.com/api/v1',
  baseUrl2: 'https://staging-dashboard.i-makeup.com/api/v2',
  hostUrl: 'https://staging.i-makeup.com',
  mapApiKey: 'AIzaSyAwN0qtzjZrzIQsgOOH_SU0X0RZ9rjWBpE',
  // hostUrl: 'https://i-makeup.ademo.net',
  hyperPayLink: "https://eu-prod.oppwa.com/v1/static/",
  recaptcha: {
    siteKey: '6LdGbnIpAAAAABJEQzZ38UICd5UpgyWCImdzFRfY',
  },
  firebaseConfig : {
    apiKey: "AIzaSyAWDsSUSR1cP6WJF4VVsBR8c1q-EghqTI0",
    authDomain: "enhanced-pen-376409.firebaseapp.com",
    projectId: "enhanced-pen-376409",
    storageBucket: "enhanced-pen-376409.appspot.com",
    messagingSenderId: "1052859949856",
    appId: "1:1052859949856:web:13036e570a8dea33922d45",
    measurementId: "G-CWJRXS4TL9"
  },
  // hostUrl: 'http://127.0.0.1:8080', // for test localize by http-server
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
