<ng-container *ngFor="let notification of notifications">
    <app-notification-type
        [type] = "notification?.type"
        [message] = "notification?.message"
    ></app-notification-type>
</ng-container>

<!-- Example of using component -->
<!-- 

    component.ts =>

    1 - Import CustomNotificationService 
    2 - Inject into constructor  
    3 - Whenever you need to show notification call method showNotification() from service 

====>    this.customNotification.showNotification({type : 'add-cart' , message : 'any message'})


    Type -> Can be one of those 'add-cart' - 'remove-cart' - 'wishlist' - 'wishlist' - 'success' - 'sm-error' - 'lg-error'
    Message -> For Short messages use type 'add-cart' - 'remove-cart' - 'wishlist' - 'wishlist' - 'sm-error'
               For Long Messages or Api messages use type 'success' - 'sm-error' - 'lg-error' 
               Example for lg messages -> 'Added to cart successfully Added to cart successfully Added to cart successfully Added to cart successfully Added to cart successfully'
 -->