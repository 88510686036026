import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { CartService } from '../../services/cart.service';
import { Product } from '../../models/interfaces/product';
import { OutputDto } from '../../models/interfaces/output-dto';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProductCardComponent } from '../product-card/product-card.component';
import { Router } from '@angular/router';
import { BigProductCardMapperService } from 'src/app/core/services/big-product-card-mapper.service';

@Component({
  standalone:true,
  selector: 'app-add-to-cart-popup',
  templateUrl: './add-to-cart-popup.component.html',
  styleUrls: ['./add-to-cart-popup.component.scss'],
  imports:[
    CommonModule,
    NgxSkeletonLoaderModule,
    ModalModule,
    forwardRef(() => ProductCardComponent)
  ]
})
export class AddToCartPopupComponent implements OnInit {
  products!: Array<Product>;
  modalRef!: BsModalRef;
  isLoading = true;
  productDescription: string = $localize`:@@eyeBlendMakeupSpongePurple: Eye-Blend Makeup Sponge, Purple`;
  selectedProduct!: Product;
  constructor(
    private modalService: BsModalService,
    private cartService: CartService ,
    private router : Router ,
    private productsMapper : BigProductCardMapperService
  ) {}
  ngOnInit(): void {
    this.selectedProduct = this.cartService.selectedProduct;
    this.getRelatedProducts(this.selectedProduct.url_key || '');
  }

  /**
   * getRelatedProducts
   * a method to get related products of an product
   * @param {number} id
   * @memberof AddToCartPopupComponent
   */
  getRelatedProducts(urlKey: string) {
    this.cartService.getRelatedProducts(urlKey).subscribe(
      (res: OutputDto<Product[]>) => {
        if (res.data) {
          this.products = res?.data ? this.productsMapper.transformProductsArray(res?.data , false) : [] ;
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  goToCart(){
    this.router.navigate(['/cart.html']) ;
    this.closeModal();
  }

  closeModal() {
    this.modalService.hide();
  }
}
