import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';
import { BehaviorSubject, Observable, finalize , map, of , tap} from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ErrorHandlingService } from 'src/app/core/services/error-handling.service';
declare global {
  interface Window { gtag: any; }
}
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  lastOrdersTab: string = '';
  isAccountPage: any;
  isHelpPage: any;
  lastTabSubject = new BehaviorSubject<string>('');
  isLoadingWishList = new BehaviorSubject<boolean>(false);
  lastTab$ = this.lastTabSubject.asObservable();
  favoriteList :  any[] | undefined;
  cartItemList : any;
  // favouriteListSubject = new BehaviorSubject<FavoriteItem[] | undefined>(undefined);
  // favouriteList$ = this.favouriteListSubject.asObservable();
  favoriteListSubject$ = new BehaviorSubject<any[]>([]);
  cartListSubject$ = new BehaviorSubject<any[]>([]);
  cartCountItem = new BehaviorSubject<number>(0);
  updateCartItems = new BehaviorSubject<boolean>(false);
  hideCategoryNav$ = new BehaviorSubject<boolean>(false);


  private loading: boolean = false;
  constructor(
    private _api: HttpService,
    private authService: AuthService,
    ) {}
  

  ////////////// Favorites
  getFavorites(): Observable<any> {
    return this._api.getReq('/customer/v3/wishlist')
  }

  getFavList() {
    this.isLoadingWishList.next(true)
    this.authService.checkUserAuth();
    if(!this.authService.isAuth){return}
    this.getFavorites().subscribe(
      (res)=>{
        this.favoriteList = res?.data ? res?.data : [];
        this.favoriteListSubject$.next(this.favoriteList || []);
        this.isLoadingWishList.next(false)
      }
    );
  }

  updateFavorites(newFavoritesList: any): void {
    let newFavoritesListIds: any[] = newFavoritesList.data.map((e:any)=>{
      return e.product.id
    })
    this.favoriteList = newFavoritesListIds;
    this.favoriteListSubject$.next(this.favoriteList || []);
  }

  getIfInWishlist(productId: any):boolean{
    let flag: boolean = false
    this.favoriteList?.forEach(e=>{
      if(e==productId){
        flag = true
      } 
    })
    return flag
  }

  addRemoveFromWishlist(productId: number|undefined): Observable<any> {
    return this._api.postReq(`/customer/wishlist/${productId}`)
  } 

  ////////////// CART
  getAllCartItemList(){
    this.authService.checkUserAuth();
    if(!this.authService.isAuth){return}
    this._api.getReq('/customer/v2/cart').subscribe(res=>{
      this.cartCountItem.next(res.items_count)
      this.cartItemList = res;
      this.cartListSubject$.next(this.cartItemList || []);
    },err=>{
      // this._errorHandling.errorHandling(err)
    })
  }

  updateCartItemList(newCartItemList: any): void {
    let itemsIds:any[] = newCartItemList.items.map((e:any)=>{
      return {item_id:e.id,product_id:e.product.id}
    })
    this.cartItemList ={
      itemsIds,
      items_count: itemsIds.length
    }
    let items = newCartItemList?.items?.map((e:any)=>{
      return {
        "item_id":e.id,
        "name":e.name,
        "coupon":e.coupon_code,
        "discount":e.discount_amount,
        "quantity":e.quantity,
        "price":e.price,
        "item_category":e.product?.category.length !=0 ? e.product?.category[0].category_name : null,
        "item_category2":e.product?.category.length !=0 ? e.product?.category[0].category_name : null
      }
    })
    window.gtag("event", "add_to_cart", {
      currency: this.cartItemList?.cart_currency_code,
      value: this.cartItemList?.grand_total,
      items: items,
      userEmail: this.cartItemList?.customer_email,
      phone: this.authService.getUserObj().phone
    })
    this.cartCountItem.next(this.cartItemList.items_count)
    this.cartListSubject$.next(this.cartItemList || []);
  }

  setCurrentTabFromProdDetails(productStatus : string){
    if (productStatus === 'pending' || productStatus === 'processing' || productStatus === 'ready_to_ship' || '') {
      this.lastTabSubject.next('pending')
      // this.lastOrdersTab = 'pending';
    } else if (productStatus === 'completed') {
      this.lastTabSubject.next('delivered')
      // this.lastOrdersTab = 'delivered';
    } else if (productStatus === 'canceled') {
      this.lastTabSubject.next('discarded')
      // this.lastOrdersTab = 'discarded';
    }
  }
}
