import { Injectable } from '@angular/core';
import { Product } from '../models/interfaces/product';
import { HttpService } from 'src/app/core/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartProducts!: Array<Product>;
  private _selectedProduct!: Product;
  constructor(private api: HttpService) {}

  /**
   * set selected product on cart popup
   *
   * @memberof CartService
   */
  set selectedProduct(p: Product) {
    this._selectedProduct = p;
  }

  /**
   *get selected product on cart popup
   *
   * @type {Product}
   * @memberof CartService
   */
  get selectedProduct(): Product {
    return this._selectedProduct;
  }
  /**
   *getCartProducts()
   *method to get all cart products from DB
   * @memberof CartService
   */
  getCartProducts() {}

  /**
   *addProductTocart
   *method to add item to cart
   * @memberof CartService
   */
  addProductTocart(id: number, body: any) {
    return this.api.postReq(`/customer/cart/add/${id}`, body);
  }
  removeProductToCart(id: any) {
    return this.api.deleteReq(`/customer/cart/remove/${id}`);
  }

  /**
   * getRelatedProducts
   * a method to get related products of an product
   * @param {string} urlKey
   * @return {*}
   * @memberof CartService
   */
  getRelatedProducts(urlKey: string) {
    return this.api.getReq(`/product/${urlKey}/cross-sell`);
  }
}
