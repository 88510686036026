import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NotificationTypeComponent } from '../notification-type/notification-type.component';

@Component({
  standalone:true,
  selector: 'app-custom-notification',
  templateUrl: './custom-notification.component.html',
  styleUrls: ['./custom-notification.component.scss'],
  imports:[
    CommonModule,
    NotificationTypeComponent
  ]
})
export class CustomNotificationComponent implements OnInit , OnDestroy {
  notifications : {type : string , message : string}[] = [] ;
  private notificationSubscription !:Subscription ;
  constructor( private customNotification : CustomNotificationService){}
  ngOnInit(): void {
    this.notificationSubscription = this.customNotification.newNotification$.subscribe((notification)=>{
    this.notifications.push(notification) ;
    setTimeout(()=>{
        this.notifications.shift() ;
      },6000)
    })
  }
  ngOnDestroy(): void {
    this.notificationSubscription.unsubscribe();
  }
}
