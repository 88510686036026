import { Injectable } from '@angular/core';
import { CustomNotificationService } from './custom-notification.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { AccountService } from 'src/app/modules/account/services/account.service';
import { ErrorHandlingService } from './error-handling.service';
import { Router } from '@angular/router';
import { OutputDto } from 'src/app/shared/models/interfaces/output-dto';
import { ProductCardComponent } from 'src/app/shared/components/product-card/product-card.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddToCartPopupComponent } from 'src/app/shared/components/add-to-cart-popup/add-to-cart-popup.component';

@Injectable({
  providedIn: 'root'
})
export class SaveActionService {
  previousAction: any = null
  setPreviousAction(action: any){
    this.previousAction = action
  }
  constructor(
    private router: Router,
    private modalService: BsModalService,
    private notifications: CustomNotificationService,
    private cartService: CartService,
    private accountService : AccountService,
    private _errorHandling: ErrorHandlingService,
  ) { }
  doAction(){
    if(this.previousAction){
      if(this.previousAction?.type == "navigate"){
        this.router.navigate([`${this.previousAction['url']}`]);
      }
      else if(this.previousAction?.type == "add"){
        let product: any = this.previousAction.product
        if(product?.type == 'configurable'){
          if(ProductCardComponent?.addCartPopup){
            ProductCardComponent.addCartPopup?.hide();
            ProductCardComponent.addCartPopup = undefined;
          }
          this.notifications.showNotification({
            type: 'warning',
            message:$localize`:@@chooseSpecificProduct:Choose a specific product to add to your cart`,
          });
          this.router.navigate([`/products/product-details/${product.url_key}`])
          return
        }
        else{
          this.cartService
          .addProductTocart(product?.id || 0, {
            product_id: product?.id,
            quantity: 1,
          })
          .subscribe(
            (res: OutputDto<any>) => {
              if (res.data) {
                let isInCartItems = true;
                this.accountService.updateCartItemList(res?.data || [])
                this.cartService.selectedProduct = product;
                let itemInCart = res?.data?.items.filter((item:any)=> item?.product?.id == product?.id);
                // this.isAddedToCart = true;
                if (isInCartItems) {
  
                  if(ProductCardComponent?.addCartPopup){
                    ProductCardComponent.addCartPopup?.hide();
                  }
                  ProductCardComponent.addCartPopup = this.modalService.show(
                    AddToCartPopupComponent,
                    Object.assign({}, { class: 'cart-add-modal', id: product.id })
                  );
                  this.notifications.showNotification({
                    type: 'add-cart',
                    message: res.message || '',
                  });
                }
              } else {
                this.notifications.showNotification({
                  type: 'lg-error',
                  message: res.message || '',
                });
              }
            },
            (err) => {
              this._errorHandling.errorHandling(err);
            }
          );
        }
      }
    }
    this.previousAction = null
    return
  }
  deleteAction(){
    this.previousAction = null
  }
}
