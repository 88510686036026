import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, ObservableInput, catchError, tap } from 'rxjs';
import { Router } from '@angular/router';
@Injectable()
export class UserInterceptorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const AuthToken = this.authService.getUserToken();

    let headers = req.headers;
    if (AuthToken) {
      headers = headers.append('Authorization', 'Bearer ' + AuthToken);
    }
    const authRequest = req.clone({
      headers: headers
    });


    return next.handle(authRequest);
  }

}
