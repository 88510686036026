import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  baseURL = environment.baseUrl;
  baseURL2 = environment.baseUrl2;
  // baseURLDomain :any=environment.baseurlDomain
  // domain:any=environment.domain
  // subDomainName:any = ''
  constructor(private http: HttpClient,
    @Inject(LOCALE_ID) public lang: string
              ) {
                // if(window.location.origin.includes(`.${this.domain}`)){
                //  this.subDomainName= window.location.origin.split('://')[1].split('.')[0]
                //   // this.baseURL=`https://${subDomainName}${this.baseURLDomain}`

                // }
              }



  getReq(path: string, options?: Object): Observable<any> {
    options = this.getOptionsWithLang(options);
    return this.http.get(`${this.baseURL}${path}`, options);
  }
  getReqV2(path: string, options?: Object): Observable<any> {
    options = this.getOptionsWithLang(options);
    return this.http.get(`${this.baseURL2}${path}`, options);
  }

  postReq(path: string, data?: any, options?: Object): Observable<any> {
    options = this.getOptionsWithLang(options);
    return this.http.post(`${this.baseURL}${path}`, data, options);
  }

  putReq(path: string, data?: any, options?: Object): Observable<any> {
    options = this.getOptionsWithLang(options);
    return this.http.put(`${this.baseURL}${path}`, data, options);
  }

  patchReq(path: string, data?: any, options?: Object): Observable<any> {
    options = this.getOptionsWithLang(options);
    return this.http.patch(`${this.baseURL}${path}`, data, options);
  }

  deleteReq(path: string, options?: Object): Observable<any> {
    options = this.getOptionsWithLang(options);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: options // The data you want to send in the body
    };
    return this.http.delete(`${this.baseURL}${path}`, httpOptions);
  }

  // options { params:{} }

  getOptionsWithLang(options:any){
    if(!this.lang){this.lang='ar'}
    if(!options){ // options is undefined
      return { params:{lang:this.lang} }
    }

    if(!options?.params){ options['params'] = {} }
    options['params']['lang'] = this.lang;
    // console.log("options=>",options)

    return options
  }

}
