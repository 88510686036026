import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  standalone: true
})
export class SortPipe implements PipeTransform {

  transform(value: any[], key: string): any[] {
    if (!Array.isArray(value) || !key) {
      return value;
    }
    return value.sort((a, b) => {
      if (parseInt(a[key]) < parseInt(b[key])) {
        return -1;
      } else if (parseInt(a[key]) > parseInt(b[key])) {
        return 1;
      } else {
        return 0;
      }
    });
  }

}
